import { render, staticRenderFns } from "./fullscreen.vue?vue&type=template&id=53de2e16&scoped=true"
import script from "./fullscreen.vue?vue&type=script&lang=js"
export * from "./fullscreen.vue?vue&type=script&lang=js"
import style0 from "./fullscreen.vue?vue&type=style&index=0&id=53de2e16&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53de2e16",
  null
  
)

export default component.exports