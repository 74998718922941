<!-- 绑定提示 -->
<template>
  <div class="container">
    <slot>
      <el-dialog title="温馨提示" :visible.sync="$store.state.showDia" :show-close="false" :close-on-click-modal="false"
        :close-on-press-escape="false" width="350px">
        <p style="font-size: 22px;">您还没有绑定户号，暂时不能办理此业务</p>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancel">暂不绑定</el-button>
          <div class="bindBtn" @click="confirm" style="background:#004DA0;">立即绑定</div>
        </div>
      </el-dialog>
    </slot>
  </div>
</template>

<script>
export default {
  //声明组件
  components: {
  },
  props: {
    // 通过父组件传递的heatNumber,判定展示提示框
    //   show:{
    //       type: Boolean,
    //       default: () => {
    //           return ''
    //       }
    // }
  },
  watch: {
  },
  data() {
    return {
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {

  },

  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {

  },
  activated() {

  },
  //创建方法
  methods: {
    cancel() {
      this.$store.state.showDia = false
      this.$router.push('/Home')

    },
    confirm() {
      this.$store.state.showDia = false
      this.$router.push('/waterbindList')
    },
  },
};
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.container {
  .text {
    display: flex;
    justify-content: center;
    line-height: 1rem;
  }

  .dialog-footer {
    display: flex;
    justify-content: flex-end;
  }

  .bindBtn {
    width: 100px;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    background: rgb(0, 77, 160);
    color: #fff;
    margin-left: 10px;
  }
}
</style>
