<template>
  <div class="third-pager">
    <div class="third">
      <iframe :src="params.doc" width="100%" height="600px" frameborder="0"></iframe>
    </div>
  </div>
</template>
<script>
import { queryStudyDetail } from "@/api/ApiConfig.js";

export default {
  components: {},
  props: ['pager', 'params'],
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      teams: []
    };
  },
  computed: {
  },
  mounted() {
    console.log(this.params.doc)
    this.queryStudyDetail()
  },
  methods: {
    async queryStudyDetail() {
      const res = await queryStudyDetail({
        id: this.laboratory_id
      })
      if (res.code === 200) {
        console.log(res)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.third-pager {
  background-color: #F4F5F8;
  .third {
    width: 100%;
    max-width: 1200px;
    min-height: 600px;
    margin: 25px auto 50px;
    box-sizing: border-box;
  }
}
</style>
