<template>
  <div class="openApi box_1200">
    <div class="item flex jc-between ai-center">
      <div class="label">接口地址: </div>
      <div class="cont flex-1">https://ai.cyzntech.com/studyPc/study/avoid/studyEnd</div>
    </div>
    <div class="item flex jc-between ai-center">
      <div class="label">请求方式: </div>
      <div class="cont flex-1">POST</div>
    </div>
    <div class="item flex jc-between ai-center">
      <div class="label">入参示例: </div>
      <prev class="cont flex-1">
        {
          "imageBase64": "string",
          "study_id": "string",
          "user_id": "string"
        }
      </prev>
    </div>
    <div class="item flex jc-between ai-center">
      <div class="label">入参字段说明: </div>
      <div class="cont flex-1">
        study_id: 实验id
        user_id: 用户id
        imageBase64: 图片base64
      </div>
    </div>
    <div class="item flex jc-between ai-center">
      <div class="label">出参格式: </div>
      <div class="cont flex-1">JSON</div>
    </div>
    <div class="item flex jc-between ai-center">
      <div class="label">出参示例: </div>
      <div class="cont flex-1">
        {
          "msg": "请求成功",
          "code": 200,
        }
      </div>
    </div>
    <div class="item flex jc-between ai-center">
      <div class="label">出参字段说明: </div>
      <div class="cont flex-1">code:200为成功 500异常</div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
    };
  },

  created() {
  },
  activated() {
  },
  methods: {
  }
};
</script>
<style lang="scss">
.openApi {
  padding-top: 109px;
  .item {
    line-height: 48px;
    border-bottom: dashed 1px #ccc;
    &:last-child {
      border-bottom-color: transparent;
    }
    .label {
      font-size: 16px;
      color: #666;
      width: 200px;
    }
    .cont {
      margin-left: 12px;
      font-size: 14px;
      color: #333;
    }
  }
}
</style>
