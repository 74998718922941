<template>
  <div class="questions">
    <div class="classify">
      <div v-for="(item, index) in types" :key="index" class="wrap flex jc-between ai-center">
        <div class="label">{{ item.name }}</div>
        <div v-if="index == 0" class="list flex-1 overflow-hidden">
          <el-collapse accordion>
            <el-collapse-item>
              <template slot="title">
                <div class="flex ai-center ellipsis overflow-hidden">
                  <div v-for="(child, idx) in item.list.slice(0, 8)" :key="idx" :class="['item cursor-pointer', { 'actived': item.actived === child.id }]" @click="handleTypeClick(item, child)">{{ child.name }}</div>
                </div>
              </template>
              <div class="flex flex-wrap ai-center">
                <div v-for="(child, idx) in item.list.slice(8)" :key="idx" :class="['item cursor-pointer', { 'actived': item.actived === child.id }]" @click="handleTypeClick(item, child)">{{ child.name }}</div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div v-else class="list flex-1 flex flex-wrap ai-center">
          <div v-for="(child, idx) in item.list" :key="idx" :class="['item cursor-pointer', { 'actived': item.actived === child.id }]" @click="handleTypeClick(item, child)">{{ child.name }}</div>
        </div>
      </div>
    </div>
    <div class="table">
      <div v-for="(item, index) in list" :key="index" class="wrap">
        <div v-if="item.topic" class="title">{{ index + 1 }}、 {{ item.topic }}</div>
        <div v-if="item.topicImage" class="title"><img :src="item.topicImage" alt=""></div>
        
        <div v-if="item.answerType == '0'" class="answer">
          <div v-for="(child, idx) in JSON.parse(item.answer)" :key="idx" class="item">
            <div v-if="child.cont">{{ alphabet[idx] }}、{{ child.cont }}</div>
            <div v-if="child.pic"><img :src="child.pic" alt=""></div>
          </div>
          <div class="item">答案：{{ item.correctAnswer }}</div>
        </div>
        <div v-if="item.answerType == '1'" class="answer">
          <div v-for="(child, idx) in JSON.parse(item.answer)" :key="idx" class="item">
            <div v-if="child.cont">{{ alphabet[idx] }}、{{ child.cont }}</div>
            <div v-if="child.pic"><img :src="child.pic" alt=""></div>
          </div>
          <div class="item">答案：{{ JSON.parse(item.correctAnswer).join(', ') }}</div>
        </div>
        <div v-if="item.answerType == '3'" class="answer">
          <div class="item">答案：{{ item.correctAnswer == '1' ? '正确' : '错误' }}</div>
        </div>
        <div v-if="item.answerType == '4'" class="answer">
          <div class="item">答案：{{ JSON.parse(item.correctAnswer).join(',') }}</div>
        </div>
        <div v-if="item.answerType == '2'" class="answer">
          <div class="item">答案：{{ item.correctAnswer }}</div>
        </div>
        <div class="answer">
          <div class="item">解析：{{ item.analysis }}</div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 40, 50, 100]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        <div class="btns text-align-center">
          <!-- <el-button type="primary" class="submit" @click="handleDownload()">下载</el-button> -->
        </div>
      </div>
    </div>
    <!-- <img src="../../assets/pcResource.png" alt=""> -->
  </div>
</template>
<script>
import { topicModuleList, topicList } from '../../api/ApiConfig'
export default {
  components: {
  },
  data() {
    return {
      alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      types: [
        {
          id: 1,
          name: '课题模块：',
          actived: '',
          list: []
        },
        {
          id: 2,
          name: '课题类型：',
          actived: '',
          list: [
            {
              id: '',
              name: '全部'
            },
            {
              id: '0',
              name: '实验操作'
            },
            {
              id: '1',
              name: '实验理论'
            }
          ]
        },
        {
          id: 3,
          name: '题目分类：',
          actived: '',
          list: [
            {
              id: '',
              name: '全部'
            },
            {
              id: '0',
              name: '单选题'
            },
            {
              id: '1',
              name: '多选题'
            },
            {
              id: '3',
              name: '判断题'
            },
            {
              id: '4',
              name: '填空题'
            },
            {
              id: '2',
              name: '解答'
            }
          ]
        }
      ],
      page: 1,
      size: 10,
      total: 10,
      list: []
    };
  },
  async created() {
    await this.topicModuleList()
    this.getData()
  },
  activated() {
  },
  methods: {
    handleTypeClick(item, child) {
      item.actived = child.id
      this.page = 1
      this.getData()
    },
    async topicModuleList() {
      const res = await topicModuleList()
      if (res.code === 200) {
        this.types[0].list = [
          {
            id: '',
            name: '全部'
          },
          ...res.data.map(item => {
            item.id = String(item.id)
            return item
          })
        ]
      }
    },
    getData() {
      const moduleId = this.types[0].list.find(item => item.id == this.types[0].actived).id
      const topicType = this.types[1].list.find(item => item.id == this.types[1].actived).id
      const answerType = this.types[2].list.find(item => item.id == this.types[2].actived).id
      topicList({
        moduleId,
        topicType,
        answerType,
        pageIndex: this.page,
        pageSize: this.size
      }).then(res => {
        if (res.code === 200) {
          this.list = res.data.list || [];
          this.total = res.data.total
        }
      });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    handleDownload() {

    }
  }
};
</script>
<style lang="scss">
.questions {
  padding-top: 109px;
  .classify {
    width: 1400px;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
    border-radius: 12px;
    padding: 20px 30px;
    margin: 0 auto;
    .wrap {
      padding: 16px 0;
      .label {
        width: 90px;
        height: 25px;
        font-weight: bold;
        font-size: 18px;
        color: #327CED;
        line-height: 25px;
        text-align: left;
        font-style: normal;
      }
      .item {
        font-size: 16px;
        color: #697B95;
        line-height: 38px;
        padding: 0 16px;
        &.actived {
          background: #327CED;
          border-radius: 6px;
          color: #FFFFFF;
        }
      }
    }
  }
  .table {
    width: 1400px;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
    border-radius: 12px;
    margin: 30px auto;
    padding: 10px 30px;
    .wrap {
      margin: 20px 0;
      .title {
        font-weight: bold;
        font-size: 22px;
        color: #254B88;
        line-height: 37px;
      }
      .answer {
        padding-left: 15px;
      }
      .item {
        font-size: 18px;
        color: #254B88;
        line-height: 37px;
      }
    }
  }
  .pagination {
    margin-top: 20px;
  }
  .btns {
    margin: 30px 0;
  }
}
</style>
