<template>
  <div class="Experiment flex box_1200">
    <PersonMenu @getData="getData" ref="childRef" />
    <HorizontalImgText
      type="experiment"
      :pageTotal="pageTotal"
      :listData="listData"
      @getPageSize="getPageSize"
      @getPageIndex="getPageIndex"
      @change="handleChangeSearch"
    />
  </div>
</template>
<script>
import { studyModuleList } from "@/api/ApiConfig.js";

import PersonMenu from "@/views/Experiment/components/menu";
import HorizontalImgText from "@/components/HorizontalImgText";
export default {
  components: {
    PersonMenu,
    HorizontalImgText
  },
  data() {
    return {
      queryParams: {
        channel: "实验",
        pageIndex: 1,
        pageSize: 10,
        type: "",
        title: "",
        listType: "",
        type2: "",
        time: ""
      },
      pageTotal: 0,
      listData: []
    };
  },
  created() {
    let query = this.$route.query;
    if (query && query.inputValue) {
      this.queryParams.title = query.inputValue;
      this.queryParams.type = "";
      let all = {
        label: "全部实验",
        value: 3,
        CheckIcon: require("@/assets/images/icon/all-blue.png"),
        unCheckIcon: require("@/assets/images/icon/all-gray.png")
      };
      this.$nextTick(() => {
        // 在此处执行你要执行的函数
        this.$refs.childRef.handleClick(all);
      });
    }
    this.getStudyModuleList();
  },
  activated() {
    let query = this.$route.query;
    if (query && query.inputValue) {
      this.queryParams.title = query.inputValue;
      this.queryParams.type = "";
      let all = {
        label: "全部实验",
        value: 3,
        CheckIcon: require("@/assets/images/icon/all-blue.png"),
        unCheckIcon: require("@/assets/images/icon/all-gray.png")
      };
      this.$nextTick(() => {
        // 在此处执行你要执行的函数
        this.$refs.childRef.handleClick(all);
      });
    }
    this.getStudyModuleList();
  },
  methods: {
    handleChangeSearch(selc, selb) {
      this.queryParams.type2 = selc
      this.queryParams.time = selb
      this.getStudyModuleList();
    },
    getPageIndex(val) {
      this.queryParams.pageIndex = val;
      this.getStudyModuleList();
    },
    getPageSize(val) {
      this.queryParams.pageSize = val;
      this.getStudyModuleList();
    },
    getData(data) {
      this.queryParams.type = data;
      if (data === "全部实验") {
        this.queryParams.type = "";
      }
      this.getStudyModuleList();
    },
    getStudyModuleList() {
      let queryParams = JSON.parse(JSON.stringify(this.queryParams))
      queryParams.type = queryParams.type === "全部实验" ? "" : queryParams.type;
      queryParams.type2 = queryParams.type2 === "全部" ? "" : queryParams.type2;
      queryParams.time = queryParams.time === "全部" ? "" : queryParams.time
      if(queryParams.type === "热门实验" || queryParams.type === "最新实验") {
        queryParams.listType = queryParams.type
        queryParams.type = ''
      } else {
        queryParams.listType = ''
      }
      studyModuleList(queryParams).then(res => {
        if (res.code === 200) {
          let listData = res.data.list;
          listData.map(item => {
            try {
              this.$set(
                item,
                "filterContent",
                item.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, "")
              );
            } catch (error) {
              console.log(error)
            }
          });
          this.listData = listData;
          this.pageTotal = res.data.total;
        }
      });
    }
  }
};
</script>
<style lang="scss">
.Experiment {
  padding-top: 109px;
}
</style>
