<template>
  <div class="myProfile flex">
    <PersonMenu />
    <div class="PersonalCenter-container">
      <Head @getInfoData="getInfoData" />
      <div class="PortraitOne">
        <article>
          <div class="title-wrapper flex">
            <div class="name">我的资料</div>
          </div>
          <div class="base_info flex align-c">
            <span>基础信息</span>
            <div class="avatar-wrapper">
              <el-avatar
                shape="square"
                :size="90"
                :src="infoform.pic ? infoform.pic : squareUrl"
              ></el-avatar>
            </div>
            <div class="info flex flex-column jus-a">
              <div class="name">{{ infoform.userName }}</div>
              <div>{{ infoform.email }}</div>
            </div>
            <div class="upload">
              <el-upload
                class="avatar-uploader"
                action="String"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :http-request="httpRequest"
                :before-upload="beforeAvatarUpload"
              >
                <!-- <img v-if="infoform.pic" :src="infoform.pic" class="avatar" /> -->
                <i v-if="!infoform.pic" class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
          <div class="form_box">
            <el-form label-width="130px" :model="infoform" :rules="rules" ref="infoform">
              <el-form-item label="昵称" prop="userName">
                <el-input placeholder="请输入昵称" v-model="infoform.userName"></el-input>
              </el-form-item>
              <el-form-item label="手机号" prop="phone" class="sign">
                <el-input placeholder="请输入手机号" v-model="infoform.phone"></el-input>
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                <el-input placeholder="请输入邮箱" v-model="infoform.email"></el-input>
              </el-form-item>
              <!-- <div class="tips">
                <i class="el-icon-warning"></i>您的真实姓名将不会被其他用户看到
              </div> -->
              <el-form-item>
                <el-button class="confirm" type="primary" @click="onSubmit('infoform')"
                  >提 交</el-button
                >
                <!-- <el-button class="cancel">取 消</el-button> -->
              </el-form-item>
            </el-form>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { MyInfo, UpMyInfo } from "@/api/ApiConfig";
import PersonMenu from "@/views/PersonalCenter/components/menu";
import Head from "@/views/PersonalCenter/components/head";
export default {
  components: { PersonMenu, Head },
  data() {
    return {
      infoform: {
        pic: "",
        userName: "",
        phone: "",
        email: ""
      },
      rules: {
        userName: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }]
      },
      squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      fileList: []
    };
  },
  watch: {},
  created() {
    // this.getInfo();
  },
  mounted() {},
  methods: {
    getInfoData(val) {
      this.infoform = val;
      if (this.infoform.phone) {
        var reg = /^(\d{3})\d{4}(\d{4})$/;
        this.infoform.phone = this.infoform.phone.replace(reg, "$1****$2");
      }
    },
    onSubmit(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          this.openLoading();
          UpMyInfo(this.infoform).then(res => {
            this.openLoading().close();
            if (res.code !== 200) {
              this.$message.error(res.msg);
              return;
            }
            this.$message.success("提交成功");
            this.getInfo();
          });
        } else {
          return false;
        }
      });
    },
    httpRequest(param) {
      var that = this;
      let formData = new FormData();
      formData.append("file", param.file);
      formData.append("FileName", param.file.name);
      formData.append("token", localStorage.getItem("pcToken") || "");
      axios
        .post("/studyPc/upload/uploadPicture", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Token: localStorage.getItem("pcToken") || ""
          }
        })
        .then(function(response) {
          let res = response.data;
          if (res.code !== 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          that.uploadView(res.data);
        })
        .catch(function(error) {
          that.$message({
            message: error,
            type: "error"
          });
        });
    },
    uploadView(url) {
      this.fileList.push({
        name: "img",
        url: `https://ai.cyzntech.com/studyPc/upload/avoid/view?fileName=${url}`
      });
      var pics = [];
      this.fileList.forEach(item => {
        pics.push(item.url);
      });
      this.infoform.pic = String(pics);
    },
    handleAvatarSuccess(res, file) {
      this.infoform.pic = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      let flag = ["png", "jpeg", "jpg", "gif"].includes(file.type.split("/")[1]);
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!flag) {
        this.$message.error("上传图片格式是 png、jpeg、jpg、gif 格式!");
      }
      if (!isLt5M) {
        this.$message.error("图片大小不能超过 5MB!");
      }
      return flag && isLt5M;
    }
  }
};
</script>

<style lang="scss" scoped>
.myProfile {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-top: 109px;
  ul {
    list-style-type: none;
  }
  .PersonalCenter-container {
    width: 970px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    .form-wrapper {
      padding: 29px 40px 40px;
      margin-bottom: 40px;
      background: #ffffff;
      border-radius: 8px;
      .title-wrapper {
        border-bottom: 1px solid #e5e5e5;
        .name {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333e55;
          border-bottom: 4px solid #3989eb;
          padding-bottom: 20px;
          margin-right: 18px;
        }
        .num {
          font-size: 16px;
          font-weight: 400;
          color: #3989eb;
          line-height: 40px;
        }
      }
    }
  }
  .PortraitOne {
    article {
      padding: 29px 40px 40px;
      margin-bottom: 40px;
      background: #ffffff;
      border-radius: 8px;
      .title-wrapper {
        border-bottom: 1px solid #e5e5e5;
        .name {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333e55;
          border-bottom: 4px solid #3989eb;
          padding-bottom: 20px;
          margin-right: 18px;
        }
        .num {
          font-size: 16px;
          font-weight: 400;
          color: #3989eb;
          line-height: 40px;
        }
      }
      .base_info {
        color: #333333;
        font-size: 16px;
        height: 90px;
        margin: 40px 100px 0 145px;
        img {
          width: 90px;
          height: 90px;
          background: #ffffff;
          border: 2px solid #e5e5e5;
          border-radius: 10px;
        }
        .avatar-wrapper {
          margin: 0 30px;
        }
        .info {
          height: 100%;
          margin-right: 100px;
          .name {
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
        }
      }
      .form_box {
        width: 600px;
        margin-top: 40px;
        margin-left: 100px;
        text-align: center;
        .tips {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #fe5555;
          padding-bottom: 61px;
          text-align: left;
          padding-left: 133px;
          margin-top: -23px;
        }
        /deep/.el-form-item {
          margin-bottom: 40px;
        }
        /deep/.el-form-item__label {
          letter-spacing: 9px;
        }
        /deep/.el-form-item.sign {
          /deep/.el-form-item__label {
            letter-spacing: 0px;
          }
        }
        /deep/.el-form-item__content {
          .el-input {
            width: 500px;
            height: 42px;
          }
        }
        .confirm {
          width: 180px;
          height: 46px;
          background: #3989eb;
          border-radius: 4px;
          margin-right: 30px;
        }
        .cancel {
          width: 180px;
          height: 46px;
          background: #e5e5e5;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
/deep/.el-upload {
  .el-icon-plus:before {
    line-height: 85px;
  }
}
/deep/.avatar-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .el-upload:hover {
    border-color: #409eff;
  }
}
/deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
}
.avatar {
  width: 90px;
  height: 90px;
  display: block;
}
</style>
