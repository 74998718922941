<template>
  <div class="Home">
    <Header />
    <div class="home_content">
      <div class="banner">
        <el-carousel height="390px">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.addr" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="data">
        <div class="data_input">
          <el-radio-group text-color="#fff" v-model="radio" class="radio">
            <el-radio label="实验">实验</el-radio>
            <el-radio label="课程">课程</el-radio>
            <el-radio label="新闻">新闻</el-radio>
          </el-radio-group>
          <div class="input">
            <el-input
              v-model="searchInput"
              placeholder="请输入内容"
              style="width:440px;height:40px;"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="middle"
              circle
              @click="handleSearch(radio, searchInput)"
            ></el-button>
          </div>
        </div>
        <div class="data_chart">
          <div class="left">
            <div class="title">
              <div class="flex-1">
                <p>热门新闻</p>
                <p>Hot News</p>
              </div>
              <div class="more" @click="handleMore('新闻')">更多>></div>
            </div>
            <div class="content">
              <ul v-for="(item, index) in newsList" :key="index">
                <li class="flex align-c cursor-pointer" @click="handleDetail(item)">
                  <p class="point"></p>
                  {{ item.title }}
                </li>
                <!-- <span v-html="item.filterContent"> </span> -->
                <div class="mark">
                  <p>
                    <img
                      src="@/assets/images/home/icons/shou_icon_blue.png"
                      alt=""
                      v-if="item.isCollect"
                    />
                    <img src="@/assets/images/home/icons/shou_icon.png" alt="" v-else />
                    <span>{{ item.collectNum }}</span>
                  </p>
                  <p>
                    <img src="@/assets/images/home/icons/eye_icon.png" alt="" />
                    <span>{{ item.viewNum }}</span>
                  </p>
                </div>
              </ul>
            </div>
          </div>
          <div class="right">
            <div class="title">
              <p>访问量排行榜</p>
              <p>Visitor Ranking</p>
            </div>
            <div class="content">
              <div class="radio_title">
                <span
                  :style="{
                    background: selIndex === index ? 'rgba(57,137,235,0.4)' : '',
                    color: selIndex === index ? '#3989EB' : '',                   
                    fontWeight: selIndex === index ? 'bold' : ''
                  }"
                  v-for="(item, index) in titleList"
                  :key="index"
                  @click="handleClick(item, index)"
                  >{{ item.title }}</span
                >
              </div>
              <div class="progress">
                <div v-for="(item, index) in percentList" :key="index">
                  <p>{{ item.channel }}</p>
                  <el-progress
                    :width="220"
                    :stroke-width="10"
                    :percentage="item.percent"
                    :color="customColor"
                  ></el-progress>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="show_content">
        <!-- <div class="up">
            <p>拓展实验教学内容</p>
            <p>创新实验呈现方式</p>
            <p class="point"></p>
            <p>延伸实验教学时空</p>
            <p>激发学生探索兴趣</p>
          </div>
          <div class="down"></div> -->
        <img src="@/assets/images/home/show_img1.png" alt="" />
      </div>
      <!-- 课程中心 -->
      <div class="course_box">
        <div class="title_box">
          <div class="title">
            <p class="one">实验中心</p>
            <p class="two">Experimental Center</p>
            <p class="line"></p>
          </div>
          <div class="more" @click="handleMore('实验')">更多>></div>
        </div>

        <div class="content_list box_1200">
          <div class="item" v-for="(item, index) in courseList" :key="index">
            <img :src="item.pic" alt="" @click="handleClickItem(item, '实验')" />
            <div class="time">发表于{{ item.createTime }}</div>
            <el-tooltip
              effect="dark"
              :content="item.name"
              placement="bottom"
            >
              <div class="xiaotitle">
                {{
                  item.name && item.name.length > 16 ? item.name.slice(0, 17) + "..." : item.name
                }}
              </div>
            </el-tooltip>
              <div class="content" v-html="item.filterContent"></div>
            <div class="shoucang flex jus-a">
              <p>
                <img
                  src="@/assets/images/home/icons/shou_icon_blue.png"
                  alt=""
                  v-if="item.isCollect"
                />
                <img
                  src="@/assets/images/home/icons/shou_icon.png"
                  alt=""
                  @click="handleShou(item, '课程')"
                  v-else
                />
                <span>{{ item.collectNum }}</span>
              </p>
              <p>
                <img src="@/assets/images/home/icons/eye_icon.png" alt="" />
                <span>{{ item.viewNum || 0 }}</span>
              </p>
              <!-- <img
                src="@/assets/images/home/icons/shou_icon_blue.png"
                alt=""
                @click="handleShou(item, '实验')"
              />
              <span>{{ item.collectNum }}</span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="show_content">
        <img src="@/assets/images/home/show_img2.png" alt="" />
      </div>
      <!-- 推荐课程 -->
      <div class="course_box recommend_box">
        <div class="title_box">
          <div class="title">
            <p class="one">推荐课程</p>
            <p class="two">Recommended Courses</p>
            <p class="line"></p>
          </div>
          <div class="more" @click="handleMore('课程')">更多>></div>
        </div>
        <div class="recommend_list box_1200">
          <div class="item" v-for="(item, index) in recommendList" :key="index">
            <img class="left_img" :src="item.pic" alt="" @click="handleClickItem(item, '课程')" />
            <div class="left">
              <div class="xiaotitle">{{ item.name }}</div>
              <div class="content" v-html="item.filterContent"></div>
              <div class="shoucang flex">
                <p>
                  <img
                    src="@/assets/images/home/icons/shou_icon_blue.png"
                    alt=""
                    v-if="item.isCollect"
                  />
                  <img
                    src="@/assets/images/home/icons/shou_icon.png"
                    alt=""
                    @click="handleShou(item, '课程')"
                    v-else
                  />
                  <span>{{ item.collectNum }}</span>
                </p>
                <p>
                  <img src="@/assets/images/home/icons/eye_icon.png" alt="" />
                  <span>{{ item.viewNum }}</span>
                </p>
              </div>
              <div class="down">
                <p>
                  <span class="name">讲师：</span>
                  <span class="value">{{ item.name }}</span>
                </p>
                <p>
                  <span class="value">{{ item.createTime }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="contentBox" v-else>
      <router-view />
    </div> -->
  </div>
</template>

<script>
import Header from "@/components/Header";
import {
  carouselList,
  channelChartNum,
  noticeList,
  studyList,
  collectItem,
  showRecord
} from "@/api/ApiConfig.js";
export default {
  components: {
    Header
  },
  props: {
    navs: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      shou_icon: require("@/assets/images/home/icons/shou_icon.png"),
      courseList: [
        {
          img: require("@/assets/images/home/item_img.png"),
          title: "实验内容信息实验内容信息实验内容信息……",
          time: "发表于2023-8-25",
          count: 220
        },
        {
          img: require("@/assets/images/home/item_img.png"),
          title: "实验内容信息实验内容信息实验内容信息……",
          time: "发表于2023-8-25",
          count: 220
        },
        {
          img: require("@/assets/images/home/item_img.png"),
          title: "实验内容信息实验内容信息实验内容信息……",
          time: "发表于2023-8-25",
          count: 220
        },
        {
          img: require("@/assets/images/home/item_img.png"),
          title: "实验内容信息实验内容信息实验内容信息……",
          time: "发表于2023-8-25",
          count: 220
        },
        {
          img: require("@/assets/images/home/item_img.png"),
          title: "实验内容信息实验内容信息实验内容信息……",
          time: "发表于2023-8-25",
          count: 220
        },
        {
          img: require("@/assets/images/home/item_img.png"),
          title: "实验内容信息实验内容信息实验内容信息……",
          time: "发表于2023-8-25",
          count: 220
        },
        {
          img: require("@/assets/images/home/item_img.png"),
          title: "实验内容信息实验内容信息实验内容信息……",
          time: "发表于2023-8-25",
          count: 220
        },
        {
          img: require("@/assets/images/home/item_img.png"),
          title: "实验内容信息实验内容信息实验内容信息……",
          time: "发表于2023-8-25",
          count: 220
        }
      ],
      recommendList: [],
      selIndex: 0,
      radio: "实验",
      searchInput: "",
      type: 0,
      activeIndex: "1",
      percentage: 20,
      customColor: "linear-gradient(90deg, #3989EB, #41E2A7)",
      titleList: [
        {
          title: "访问量",
          value: 0
        },
        {
          title: "综合评分",
          value: 1
        },
        {
          title: "收藏量",
          value: 3
        }
      ],
      bannerList: [],
      percentList: [],
      newsList: []
    };
  },
  watch: {
    // immediate: true,
    // deep: true
  },
  created() {
    this.getCarouselList();
    this.getchannelChartNum();
    this.getnoticeList();
    this.getstudyList1();
    this.getstudyList2();
  },
  mounted() {},
  methods: {
    handleDetail(item) {
      this.doRead(item);
      this.$router.push({
        path: "/newsDetail",
        query: item
      });
    },
    doRead(item) {
      showRecord({
        channel: "新闻",
        id: item.id
      }).then(res => {});
    },
    handleSearch(type, value) {
      if (type == "新闻") {
        var path = "/news";
      } else if (type == "实验") {
        var path = "/experiment";
      } else if (type == "课程") {
        var path = "/course";
      }
      this.$router.push({
        path,
        query: {
          inputValue: value
        }
      });
    },
    // 轮播图
    getCarouselList() {
      carouselList().then(res => {
        if (res.code === 200) {
          this.bannerList = res.data || [];
        }
      });
    },
    handleClick(item, index) {
      this.type = item.value;
      this.selIndex = index;
      this.getchannelChartNum();
    },
    // 排行榜
    getchannelChartNum() {
      channelChartNum({
        type: this.type // 0访问量 1综合评分 3收藏量
      }).then(res => {
        if (res.code === 200) {
          if (res.data && res.data.length > 0) {
            const max_num = eval(res.data.map(item => Number(item.num)).join('+'))
            this.percentList = res.data.map(item => {
              item.num = Number(item.num)
              item.percent = parseFloat((Number(item.num) / max_num * 100).toFixed(2))
              return item
            });
          } else {
            this.percentList = [
              {
                channel: "新闻",
                num: 0,
                percent: 0
              },
              {
                channel: "课程",
                num: 0,
                percent: 0
              },
              {
                channel: "实验",
                num: 0,
                percent: 0
              }
            ];
          }
        }
      });
    },
    // 热门新闻
    getnoticeList() {
      noticeList({
        channel: "新闻",
        pageIndex: 1,
        pageSize: 10
      }).then(res => {
        if (res.code === 200) {
          let newsList = res.data.list || [];
          newsList.map(item => {
            this.$set(
              item,
              "filterContent",
              item.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, "")
            );
          });
          this.newsList = newsList;
        }
      });
    },
    // 实验
    getstudyList1() {
      studyList({
        channel: "实验",
        pageIndex: 1,
        pageSize: 10
      }).then(res => {
        if (res.code === 200) {
          let courseList = res.data.list || [];
          if (courseList && courseList.length > 0) {
            courseList.map(item => {
              try {
                this.$set(
                  item,
                  "filterContent",
                  item.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, "").slice(0, 30) + "..."
                );
              } catch (error) {
                console.log(error)
              }
            });
            this.courseList = courseList.slice(0, 8);
          } else {
            this.courseList = [];
          }
        }
      });
    },
    getstudyList2() {
      studyList({
        channel: "课程",
        pageIndex: 1,
        pageSize: 10
      }).then(res => {
        if (res.code === 200) {
          let recommendList = res.data.list || [];
          if (recommendList && recommendList.length > 0) {
            recommendList.map(item => {
              this.$set(
                item,
                "filterContent",
                item.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, "").slice(0, 30) + "..."
              );
            });
            this.recommendList = recommendList.slice(0, 6);
          } else {
            this.recommendList = [];
          }
        }
      });
    },
    handleShou(item, channel) {
      collectItem({
        channel,
        id: item.id
      }).then(res => {
        if (res.code === 200) {
          if (channel === "实验") {
            this.getstudyList1();
          } else {
            this.getstudyList2();
          }
        }
      });
    },
    // 获取详情
    handleClickItem(item, type) {
      showRecord({
        channel: type,
        id: item.id
      }).then(res => {
        if (res.code === 200) {
        }
      });

      var path = "";
      if (type === "实验") {
        localStorage.setItem('laboratory_id', item.id)
        localStorage.setItem('template', item.template)
        localStorage.setItem('pager', item.template === 'template1' ? 'pager1Home' : item.template === 'template2' ? 'pager2Home' : 'pager3Home')
        localStorage.setItem('pager_params', JSON.stringify({}))
        const href = this.$router.resolve({
          path: `/laboratory`,
          query: {},
        }).href;
        window.open(href, "_blank");
      } else {
        path = "/course/Detail";
        this.$router.push({
          path,
          query: {
            id: item.id
          }
        });
      }
    },
    handleMore(type) {
      if (type === "实验") {
        this.$router.push("/experiment");
      } else if(type === "新闻") {
        this.$router.push("/news");
      } else {
        this.$router.push("/course");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.Home {
  width: 100%;
  .box_1200 {
    width: 1200px;
    height: 100%;
  }

  .home_content {
    width: 100%;
    .banner {
      width: 100%;
      height: 390px;
      margin-top: 70px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .data {
      width: 100%;
      height: 390px;
      background: #e7f3ff;
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 999;
      .data_input {
        width: 614px;
        height: 120px;
        background: rgba($color: #092d64, $alpha: 0.8);
        box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -72px;
        .radio {
          margin-left: -230px;
        }
        .input {
          margin-top: 20px;
          button {
            margin-left: 14px;
          }
        }
      }
      .data_chart {
        width: 1200px;
        height: 340px;
        background: #ffffff;
        box-shadow: 0px 4px 30px 0px rgba(0, 60, 164, 0.2);
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: -35px;
        padding: 30px 50px;
        box-sizing: border-box;
        z-index: 999;
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          .flex-1 {
            flex: 1;
          }
          p:first-child {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333e55;
          }
          .more {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            cursor: pointer;
          }
        }
        .content {
          cursor: pointer;
          .remark {
            margin-left: 10px;
            color: #3989eb;
          }
        }
        .cursor-pointer {
          cursor: pointer;
          &:hover {
            text-decoration: underline;
            color: #3989eb;
          }
        }
        .left {
          width: 689px;
          margin-right: 59px;
          display: flex;
          flex-direction: column;
          .title {
            margin-bottom: 24px;
          }
          .content {
            overflow-y: scroll;
          }
          ::-webkit-scrollbar {
            width: 2px;
            background: transparent;
          }
          ul {
            border-bottom: 1px solid #e5e5e5;
            margin-top: 20px;
            li {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #333333;
              line-height: 30px;
              .point {
                width: 6px;
                height: 6px;
                background: #333e55;
                border-radius: 50%;
                margin-right: 10px;
              }
            }
            p {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
            }
            .mark {
              display: flex;
              p {
                margin-right: 10px;
                line-height: 40px;
                display: flex;
                align-items: center;
              }
              img {
                width: 15px;
                height: 15px;
                margin-right: 7px;
              }
              span {
                font-size: 16px;
                font-family: Tensentype RuiHeiJ-W4;
                font-weight: 500;
                color: #333e55;
              }
            }
          }
        }
        .right {
          flex: 1;
          .radio_title {
            margin-top: 35px;
            margin-bottom: 33px;
            span {
              font-size: 14px;
              display: inline-block;
              width: 100px;
              text-align: center;
              height: 32px;
              line-height: 32px;
              background: #ffffff;
              border: 1px solid #e5e5e5;
              border-radius: 16px;
              margin-right: 15px;
              cursor: pointer;
            }
          }
          .progress {
            div {
              width: 100%;
              height: 35px;
              display: flex;
              align-items: center;
              p {
                width: 120px;
              }
            }
          }
        }
      }
    }
    .show_content {
      width: 100%;
      height: 300px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .course_box {
      width: 100%;
      padding: 30px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title_box {
        width: 1200px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      .more {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        position: absolute;
        right: 0;
        cursor: pointer;
      }
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;

        .one {
          font-size: 44px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333e55;
        }
        .two {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333e55;
        }
        .line {
          width: 40px;
          height: 4px;
          background: #3989eb;
          border-radius: 2px;
          margin-top: 10px;
        }
      }
      .content_list {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 30px;
        .item {
          width: 270px;
          margin-top: 50px;
          margin-right: 29px;
          .content{
            height: 42px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            margin: 13px 0;
          }
          img {
            width: 270px;
            height: 180px;
            cursor: pointer;
          }
          .time {
            text-align: center;
            font-size: 16px;
            line-height: 46px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          .xiaotitle {
            margin-left: 15px;
            margin-bottom: 12px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333;
            text-align: center;
          }
          .shoucang {
            text-align: center;
            img {
              width: 15px;
              height: 15px;
              margin-right: 5px;
              cursor: pointer;
            }
            span {
              font-size: 16px;
              font-family: Tensentype RuiHeiJ-W4;
              font-weight: 500;
              color: #333e55;
            }
          }
        }
      }
    }
    .recommend_box {
      background: #f2f9fe;
      .recommend_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
          width: 372px;
          height: 190px;
          margin-top: 40px;
          box-shadow: 1px 1px 10px 1px #66666645;
          border-radius: 8px;
          display: flex;
          margin-right: 12px;
          margin-left: 12px;
          .left_img {
            width: 150px;
            height: 100%;
            border-radius: 8px;
            cursor: pointer;
          }
          .left {
            width: 233px;
            background: #ffffff;
            border-radius: 8px;
            padding: 15px;
            box-sizing: border-box;
            margin-left: -10px;
            .xiaotitle {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
            }
            .content {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              margin-top: 13px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
            .shoucang {
              // justify-content: space-between;
              margin-top: 23px;
              margin-bottom: 17px;
              p:nth-child(2) {
                margin-left: 40px;
              }
              img {
                width: 15px;
                height: 15px;
                margin-top: 5px;
                margin-right: 5px;
                cursor: pointer;
              }
              span {
                font-size: 16px;
                font-family: Tensentype RuiHeiJ-W4;
                font-weight: 500;
                color: #333e55;
              }
            }
            .down {
              display: flex;
              justify-content: space-between;
              font-size: 12px;
              color: #666;
              border-top: 1px solid #ccc;
              p {
                margin-top: 10px;
                .value {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.el-radio__label {
  color: #fff;
}
</style>
