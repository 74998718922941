var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"openApi box_1200"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"item flex jc-between ai-center"},[_c('div',{staticClass:"label"},[_vm._v("入参示例: ")]),_c('prev',{staticClass:"cont flex-1"},[_vm._v(" { \"imageBase64\": \"string\", \"study_id\": \"string\", \"user_id\": \"string\" } ")])],1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item flex jc-between ai-center"},[_c('div',{staticClass:"label"},[_vm._v("接口地址: ")]),_c('div',{staticClass:"cont flex-1"},[_vm._v("https://ai.cyzntech.com/studyPc/study/avoid/studyEnd")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item flex jc-between ai-center"},[_c('div',{staticClass:"label"},[_vm._v("请求方式: ")]),_c('div',{staticClass:"cont flex-1"},[_vm._v("POST")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item flex jc-between ai-center"},[_c('div',{staticClass:"label"},[_vm._v("入参字段说明: ")]),_c('div',{staticClass:"cont flex-1"},[_vm._v(" study_id: 实验id user_id: 用户id imageBase64: 图片base64 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item flex jc-between ai-center"},[_c('div',{staticClass:"label"},[_vm._v("出参格式: ")]),_c('div',{staticClass:"cont flex-1"},[_vm._v("JSON")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item flex jc-between ai-center"},[_c('div',{staticClass:"label"},[_vm._v("出参示例: ")]),_c('div',{staticClass:"cont flex-1"},[_vm._v(" { \"msg\": \"请求成功\", \"code\": 200, } ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item flex jc-between ai-center"},[_c('div',{staticClass:"label"},[_vm._v("出参字段说明: ")]),_c('div',{staticClass:"cont flex-1"},[_vm._v("code:200为成功 500异常")])])
}]

export { render, staticRenderFns }