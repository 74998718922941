<template>
  <div class="resource">
    <div class="classify">
      <div class="wrap flex jc-between ai-center">
        <div class="label">专业大类：</div>
        <div class="list flex-1 flex ai-center">
          <div v-for="(item, index) in specialityOpts" :key="index"
            :class="['item cursor-pointer', { 'actived': specialityActived === item.value }]"
            @click="handleTypeClick('specialityActived', item)">{{ item.label }}</div>
        </div>
      </div>
      <div class="wrap flex jc-between ai-center">
        <div class="label">课程类型：</div>
        <div class="list flex-1 flex ai-center">
          <div v-for="(item, index) in courseTypeOpts" :key="index"
            :class="['item cursor-pointer', { 'actived': courseTypeActived === item.value }]"
            @click="handleTypeClick('courseTypeActived', item)">{{ item.label }}</div>
        </div>
      </div>
      <div class="wrap flex jc-between ai-center">
        <div class="label">课程系列：</div>
        <div class="list flex-1 flex ai-center">
          <div v-for="(item, index) in courseSeriesOpts" :key="index"
            :class="['item cursor-pointer', { 'actived': courseSeriesActived === item.value }]"
            @click="handleTypeClick('courseSeriesActived', item)">{{ item.label }}</div>
        </div>
      </div>
      <div class="wrap flex jc-between ai-center">
        <div class="label">文件类型：</div>
        <div class="list flex-1 flex ai-center">
          <div v-for="(item, index) in fileTypeOpts" :key="index"
            :class="['item cursor-pointer', { 'actived': fileTypeActived === item.value }]"
            @click="handleTypeClick('fileTypeActived', item)">{{ item.label }}</div>
        </div>
      </div>
    </div>
    <div class="table flex flex-wrap">
      <div v-for="(item, index) in list" :key="index" class="item flex flex-column relative cursor-pointer">
        <!-- 文本文档 -->
        <div v-if="item.fileType == '14'" class="img flex jc-center ai-center">
          <i class="el-icon-document" style="font-size: 180px;color: #ccc;" @click="handleOpenWorld('https://aiht.cyzntech.com/File/' + item.fileUrl)"></i>
        </div>
        <!-- <div v-if="item.fileType == '14'" class="img">
          <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=' + item.fileUrl" width='260px' height="244px" frameborder="0" class="iframe"></iframe>
        </div> -->
        <!-- 图片 -->
        <el-image v-else-if="item.fileType == '18'" class="img" :src="'https://aiht.cyzntech.com/File/' + item.fileUrl" :preview-src-list="['https://aiht.cyzntech.com/File/' + item.fileUrl]" />
        <!-- 视频资料 -->
        <video
          v-else-if="item.fileType == '15'"
          class="img"
          controls
          :src="'https://aiht.cyzntech.com/File/' + item.fileUrl"
        ></video>
        <!-- 全景图资料 -->
        <el-image v-else-if="item.fileType == '16'" class="img" :src="'https://aiht.cyzntech.com/File/' + item.fileUrl" :preview-src-list="['https://aiht.cyzntech.com/File/' + item.fileUrl]" />
        <!-- 模型资料 -->
        <div v-else-if="item.fileType == '17'" class="img">
          <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=' + item.fileUrl" width='260px' height="244px" frameborder="0" class="iframe"></iframe>
        </div>
        <div class="txt">{{ item.fileName }}</div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[10, 20, 40, 50, 100]" :page-size="size"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- <img src="../../assets/pcResource.png" alt=""> -->
  </div>
</template>
<script>
import { infoFileTypeList, getResource } from '@/api/ApiConfig'
export default {
  components: {
  },
  data() {
    return {
      specialityOpts: [],
      specialityActived: '',
      courseTypeOpts: [],
      courseTypeActived: '',
      courseSeriesOpts: [],
      courseSeriesActived: '',
      fileTypeOpts: [],
      fileTypeActived: '',
      page: 1,
      size: 10,
      total: 0,
      list: []
    };
  },
  async created() {
    await this.infoFileTypeList();
    this.getData()
  },
  methods: {
    handleOpenWorld(path) {
      if(['docx', 'docm', 'dotm', 'dotx', 'xlsx', 'xlsb', 'xls', 'xlsm', 'pptx', 'ppsx', 'ppt', 'pps', 'pptm', 'potm', 'ppam', 'potx', 'ppsm'].some(item => path.slice(-4).includes(item))) {
        path = 'https://view.officeapps.live.com/op/view.aspx?src=' + path
      }
      window.open(path, "_blank");
    },
    async infoFileTypeList() {
      const opts1 = await infoFileTypeList({ fileType: '1'})
      this.specialityOpts = [
        {
          value: '',
          label: '全部'
        },
        ...opts1.data.map(item => ({
          value: item.id,
          label: item.typeName
        }))
      ]
      const opts2 = await infoFileTypeList({ fileType: '2'})
      this.courseTypeOpts = [
        {
          value: '',
          label: '全部'
        },
        ...opts2.data.map(item => ({
          value: item.id,
          label: item.typeName
        }))
      ]
      const opts3 = await infoFileTypeList({ fileType: '3'})
      this.courseSeriesOpts = [
        {
          value: '',
          label: '全部'
        },
        ...opts3.data.map(item => ({
          value: item.id,
          label: item.typeName
        }))
      ]
      const opts4 = await infoFileTypeList({ fileType: '4'})
      this.fileTypeOpts = [
        {
          value: '',
          label: '全部'
        },
        ...opts4.data.map(item => ({
          value: item.id,
          label: item.typeName
        }))
      ]
    },
    handleTypeClick(label, item) {
      this[label] = item.value
      this.page = 1
      this.getData()
    },
    getData() {
      getResource({
        pageIndex: this.page,
        pageSize: this.size,
        courseSeries: this.courseSeriesActived,
        courseType: this.courseTypeActived,
        fileType: this.fileTypeActived,
        speciality: this.specialityActived
      }).then(res => {
        if (res.code === 200) {
          this.list = res.data.records || [];
          this.total = res.data.total
        }
      });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
  }
};
</script>
<style lang="scss">
.resource {
  padding-top: 109px;

  .classify {
    width: 1400px;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px 0px rgba(45, 77, 130, 0.11);
    border-radius: 12px;
    padding: 20px 30px;
    margin: 0 auto;

    .wrap {
      padding: 16px 0;

      .label {
        width: 90px;
        height: 25px;
        font-weight: bold;
        font-size: 18px;
        color: #327CED;
        line-height: 25px;
        text-align: left;
        font-style: normal;
      }

      .item {
        font-size: 16px;
        color: #697B95;
        line-height: 38px;
        padding: 0 16px;

        &.actived {
          background: #327CED;
          border-radius: 6px;
          color: #FFFFFF;
        }
      }
    }
  }

  .table {
    width: 1400px;
    margin: 20px auto;

    .item {
      width: 260px;
      height: 279px;
      margin: 10px;
      img, video {
        object-fit: contain;
      }
      .img {
        width: 260px;
        height: 244px;
        overflow: hidden;
        object-fit: contain;
        background: #ffffff;
        .iframe {
          width: 100%;
          height: 100%;
        }
      }

      .txt {
        width: 280px;
        font-weight: bold;
        font-size: 18px;
        color: #234984;
        line-height: 25px;
        text-align: center;
        margin-top: 10px;
      }
    }
  }

  .pagination {
    margin-top: 20px;
  }
}
</style>
