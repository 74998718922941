<template>
  <div class="bannerContainer flex jus-c">
    <div class="Content  box_1200">
      <div class="up_box flex jus-b">
        <div class="left">
          <div v-if="data.videoSrc">
            <video
              v-if="fileType == 'video'"
              class="fileCont"
              ref="videoRef"
              width="900px"
              disablePictureInPicture
              controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
              controls
              autoplay
              :src="data.videoSrc"
            />
            <el-image v-else-if="fileType == 'img'" class="fileCont" :src="data.videoSrc" fit="contain" />
            <div v-else-if="fileType == 'pdf'" class="fileCont">
              <iframe :src="data.videoSrc" frameborder="0"></iframe>
            </div>
            <div v-else-if="fileType == 'doc'" class="fileCont">
              <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=' + data.videoSrc" frameborder="0"></iframe>
            </div>
            <div v-else-if="fileType == 'txt'" class="fileCont">
              <iframe :src="data.videoSrc" frameborder="0"></iframe>
            </div>
            <div v-else class="fileCont">
              <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=' + data.videoSrc" frameborder="0"></iframe>
            </div>
            <i v-if="fileType == 'video'" class="el-icon-full-screen fullIcon" @click="pathFull"></i>
          </div>
          <img class="videoImg" v-else :src="data.pic" alt="" />
        </div>
        <div class="right">
          <Chapter :data="data" @handleLearn="handleLearn" />
        </div>
      </div>
      <div class="down_box flex align-c jus-b">
        <div class="left flex">
          <span class="text">{{ data.name }}</span>
          <el-rate v-model="average" :disabled="average != null" text-color="#3989EB" :colors="['#3989EB', '#3989EB', '#3989EB']" disabled-void-color="#A8CFFF" @change="handleChangeAverage()"></el-rate>
        </div>
        <div class="right flex jus-a">
          <div class="button flex">
            <div class="btn1" @click="handleClass">
              {{ type === "实验" ? "参与实验" : "我要上课" }}
            </div>
            <div class="btn2" @click="handleExam('我要考试')">
              我要考试
            </div>
          </div>
          <div class="mark flex align-c">
            <p class="mark flex align-c">
              <img
                src="@/assets/images/home/icons/shou_icon_blue.png"
                @click="handleCollect('2')"
                alt=""
                v-if="data.isCollect"
              />
              <img
                src="@/assets/images/home/icons/shou_icon2.png"
                alt=""
                @click="handleCollect('1')"
                v-else
              />
              <span>{{ data.collectNum }}</span>
            </p>
            <p class="mark flex align-c">
              <img @click="handleRead" src="@/assets/images/home/icons/eye_icon2.png" alt="" />
              <span>{{ data.viewNum }}</span>
            </p>
          </div>
        </div>
      </div>

      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>{{
          tipValue == "我要考试"
            ? "考试系统正在开发中"
            : tipValue == "我要上课"
            ? "上课系统正在开发中..."
            : "实验系统正在开发中..."
        }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { queryStudyDetail, collectItem, showRecord, myStudyScore, inStudyScore, inStudyJointLog, myStudyViewsJointLog, gotoClass } from "@/api/ApiConfig";
import Chapter from "@/components/Chapter.vue";
export default {
  components: {
    Chapter
  },
  data() {
    return {
      courses: [
        {
          title: "Course A",
          children: [{ jointName: "第一节" }, { jointName: "第二节" }],
          content: "Content for course A"
        },
        { title: "Course B", children: [{ jointName: "第一节" }], content: "Content for course B" },
        { title: "Course C", children: [{ jointName: "第一节" }], content: "Content for course C" },
        {
          title: "Course A",
          children: [{ jointName: "第一节" }, { jointName: "第二节" }],
          content: "Content for course A"
        },
        { title: "Course B", children: [{ jointName: "第一节" }], content: "Content for course B" },
        { title: "Course C", children: [{ jointName: "第一节" }], content: "Content for course C" }
      ],
      isOpened: false, // 控制展开与关闭状态的变量
      shou_icon: require("@/assets/images/home/icons/shou_icon.png"),
      dialogVisible: false,
      tipValue: "我要考试",
      data: {},
      average: null
    };
  },
  computed: {
    fileType() {
      const suffix = this.data.videoSrc.split('.')[this.data.videoSrc.split('.').length - 1]
      if (suffix == 'pdf') {
        return 'pdf'
      } else if (['docx', 'doc', 'xlsx', 'xls', 'pptx', 'ppt'].includes(suffix)) {
        return 'doc'
      } else if (['txt'].includes(suffix)) {
        return 'txt'
      } else if (['mp4', 'avi', 'wmv', 'mpeg', 'mp4', 'm4v', 'mov', 'asf', 'flv', 'f4v', 'rmvb', 'rm', '3gp', 'vob'].includes(suffix)) {
        return 'video'
      } else if (['bmp', 'jpg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'psd', 'cdr', 'pcd', 'dxf', 'ufo', 'eps', 'ai', 'raw', 'WMF', 'webp', 'avif', 'apng'].includes(suffix)) {
        return 'img'
      } else {
        return ''
      }
    }
  },
  props: {
    type: {
      type: String,
      default: ""
    }
  },
  watch: {
    $route() {
      this.getData();
      this.getMyStudyScore();
    }
  },
  mounted() {
    this.getData();
    this.getMyStudyScore();
  },
  methods: {
    pathFull() {
      let routeData = this.$router.resolve({
        path: "/course/fullscreen",
        query: {
          id: this.$route.query.id,
          videoLink: this.data.videoSrc
        }
      });
      window.open(routeData.href, '_blank');
    },
    // 开始学习
    handleLearn(course, item) {
      this.data.videoSrc = item.videoLink;
      inStudyJointLog({
        chapterId: course.id,
        jointId: item.id,
        studyId: this.$route.query.id,
      })
    },
    getData() {
      queryStudyDetail({
        id: this.$route.query.id
      }).then(async res => {
        if (res.code === 200) {
          res.data.collectNum = res.data ? Number(res.data.collectNum) : 0
          let data = res.data;
          data.filterContent = data.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, "");
          this.data = data;
          this.$set(this.data, "videoSrc", data.video);
          myStudyViewsJointLog({
            studyId: this.$route.query.id
          }).then(response => {
            if(response.data.length > 0) {
              let videoSrc = data.video
              data.chapterList.map(item => {
                if(item.children && item.children.length > 0) {
                  item.children.map(child => {
                    if(child.id == response.data[0].jointId) {
                      videoSrc = child.videoLink
                    }
                  })
                }
              })
              this.$set(this.data, "videoSrc", videoSrc);
            }
          })
        }
      });
    },
    getMyStudyScore() {
      myStudyScore({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.average = res.data ? Number(res.data.divide) : null
        }
      });
    },
    // 收藏
    handleCollect(type) {
      let loginFlag = localStorage.getItem("pcToken") || "";
      if (!loginFlag) {
        this.$message.error("请登录");
        setTimeout(() => {
          this.$router.replace("/login");
        }, 1500);
        return;
      } else {
        collectItem({
          channel: this.type === "experiment" ? "实验" : "课程",
          id: this.$route.query.id
        }).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg);
            return;
          }
          this.getData();
          if (type === "1") {
            this.$message.success("收藏成功");
          } else {
            this.$message.success("已取消收藏");
          }
        });
      }
    },
    handleRead() {
      showRecord({
        channel: this.type === "experiment" ? "实验" : "课程",
        id: this.$route.query.id
      }).then(res => {
        this.$parent.getData();
      });
    },
    // 我要上课
    async handleClass() {
      let loginFlag = localStorage.getItem("pcToken") || "";
      if (!loginFlag) {
        this.$message.error("请登录");
        setTimeout(() => {
          this.$router.replace("/login");
        }, 1500);
        return;
      }
      if (this.type === "实验") {
        this.tipValue = "参与实验";
        if (this.data.studyLink.includes("http")) {
          window.open(this.data.studyLink, "_blank");
        } else {
          this.dialogVisible = true;
        }
      } else {
        this.tipValue = "我要上课";
        await gotoClass({
          channel: '课程',
          id: this.$route.query.id
        })
        showRecord({
          channel: "课程",
          id: this.$route.query.id
        });
        let routeData = this.$router.resolve({
          path: "/course/fullscreen",
          query: {
            id: this.$route.query.id,
            videoLink: this.data.videoSrc
          }
        });
        window.open(routeData.href, '_blank');
      }
    },
    // 视频切换
    handleVideo(type) {
      this.data.videoSrc = this.data[type];
      this.$nextTick(() => {
        this.$refs.videoRef.play()
      });
    },
    // 我要考试
    handleExam() {
      let loginFlag = localStorage.getItem("pcToken") || "";
      if (!loginFlag) {
        this.$message.error("请登录");
        setTimeout(() => {
          this.$router.replace("/login");
        }, 1500);
        return;
      }
      if (this.data.testLink.includes("http")) {
      } else {
        this.tipValue = "我要考试";
        this.dialogVisible = true;
      }
    },
    // 评分
    handleChangeAverage() {
      let loginFlag = localStorage.getItem("pcToken") || "";
      if (!loginFlag) {
        this.$message.error("请登录");
        setTimeout(() => {
          this.$router.replace("/login");
        }, 1500);
        return;
      } else {
        inStudyScore({
          id: this.$route.query.id,
          divide: this.average,
        }).then(() => {
          this.$message.success("评分成功")
          this.getData();
          this.getMyStudyScore();
        })
      }
    }
  }
};
</script>
<style lang="scss">
.bannerContainer {
  width: 100%;
  height: 616px;
  background: #2e3542;
  margin: 0 auto;
  padding: 24px;
  box-sizing: border-box;
  .Content {
    height: 572px;
    background: #24282f;
    border-radius: 8px;

    background: transparent;
    box-sizing: border-box;
    .up_box {
      background: #24282f;
      border-radius: 8px;
      .left {
        width: 900px;
        height: 504px;
        position: relative;
        overflow: hidden;
        .fileCont {
          width: 100%;
          height: 504px;
          object-fit: cover;
          position: relative;
          iframe {
            width: 100%;
            height: 504px;
          }
        }
        /*音量按钮*/
        video::-webkit-media-controls-volume-control-container {
          display: none !important;
        }
        /*全屏*/
        video::-webkit-media-controls-fullscreen-button {
          display: none !important;
        }
        /*更多选项 --然而并不生效*/
        video::-internal-media-controls-overflow-button {
          display: none !important;
        }
        .fullIcon {
          color: #ffffff;
          position: absolute;
          right: 16px;
          bottom: 39px;
          cursor: pointer;
        }
        .videoImg {
          width: 100%;
          height: auto;
          display: block;
        }
      }
      .right {
        width: 420px;
        height: 505px;
        background: #15181e;
        border-radius: 6px;
        position: relative;
        .title {
          height: 70px;
          line-height: 70px;
          padding-left: 10px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }

        ::-webkit-scrollbar {
          width: 2px;
          background: transparent;
        }
        .list-group {
          color: #fff;
          height: 435px;
          overflow-y: scroll;
          .list-li {
            border-bottom: 1px solid #15181e;
            cursor: pointer;
          }
          .course_box {
            width: 416px;
            height: 80px;
            background: #27282b;
            border-radius: 4px 4px 0px 0px;
            padding: 13px;
            box-sizing: border-box;
          }
          .ctitle {
            font-size: 16px;
            font-family: Microsoft YaHei;
            color: #fff;
            i {
              margin-right: 8px;
            }
          }
          .child {
            padding: 10px 33px 10px;
          }
          .startBtn {
            cursor: pointer;
            margin-top: 10px;
            font-size: 12px;
            width: 70px;
            text-align: center;
            height: 24px;
            line-height: 24px;
            border: 1px solid #fff;
            border-radius: 12px;
            color: #fff;
            margin-left: 20px;
          }
        }
      }
    }
    .down_box {
      width: 100%;
      height: 68px;
      padding: 0 19px;
      box-sizing: border-box;
      background: #24282f;
      margin-top: -5px;
      border-radius: 8px;
      .left {
        width: 777px;
        span.text {
          color: #fff;
          margin-right: 40px;
        }
      }
      .right {
        flex: 1;
        .mark {
          p {
            img {
              width: 15px;
              height: 17px;
              margin-right: 7px;
              cursor: pointer;
            }
            span {
              font-size: 16px;
              font-family: Tensentype RuiHeiJ-W4;
              font-weight: 500;
              color: #999999;
              margin-right: 40px;
            }
          }
        }
        .button {
          .btn1,
          .btn2 {
            width: 90px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            border-radius: 16px;
            font-size: 14px;
            cursor: pointer;
          }
          .btn1 {
            border: 1px solid #3989eb;
            background: #3989eb;
            color: #fff;
            margin-right: 20px;
          }
          .btn2 {
            border: 1px solid #83bf54;
            background: #83bf54;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
