<template>
  <div class="Course_detail flex flex-column align-c">
    <HeaderContent :id="this.$route.query.id" type="课程" />
    <div class="center_box flex jus-b box_1200">
      <div class="left">
        <div class="radio_title">
          <span
            :style="{
              background: selIndex === index ? 'linear-gradient(135deg, #28B9F5, #3989EB)' : '',
              color: selIndex === index ? '#fff' : '',
              fontWeight: selIndex === index ? 'bold' : ''
            }"
            v-for="(item, index) in titleList"
            :key="index"
            @click="handletitleClick(index)"
            >{{ item.title }}</span
          >
        </div>
        <div class="profile_content" v-if="selIndex === 0">
          <div class="items">
            <div class="title">课程简介</div>
            <div class="redu_content ql-editor" v-html="data.content"></div>
          </div>
          <div v-for="(item, index) in data.descs" :key="index" class="items">
            <div class="title">{{ item.label }}</div>
            <div class="txt">
              <img class="identIcon" src="@/assets/images/icon/ident_icon.png" alt="" />{{
                item.value
              }}
            </div>
          </div>
        </div>
        <div class="eva_contentBox flex flex-column jc-between ai-normal" v-if="selIndex === 1">
          <div class="flex-1 overflow-auto">
            <div class="eva_content" v-for="(item, index) in evalData" :key="index">
              <div>
                <CommenInfo :data="item" @submit="handleSubmitReplay"></CommenInfo>
                <div class="replay">
                </div>
              </div>
            </div>
            <div class="pagination">
              <el-pagination
                align="center"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 40, 50, 100]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pageTotal"
              >
              </el-pagination>
            </div>
          </div>
          <div class="comments_content">
            <div class="commen_text">
              <el-input type="text" placeholder="请输入评价内容" v-model="form.evaluate">
              </el-input>
            </div>
            <div class="comments_btn" @click="handleSubmit()">回复</div>
          </div>
        </div>
        <div class="eva_contentBox" v-if="selIndex === 2">
          <el-collapse  v-model="accordionName" accordion class="eva_files">
            <el-collapse-item v-for="(item, index) in data.chapterList" :key="index" :name="item.id" :class="['accordion_item', { 'actived': accordionName === item.id}]">
              <template slot="title">
                <div class="flex chapter_title">
                  <img v-if="accordionName === item.id" class="chapter_icon" src="../../assets/images/other/chapter_icon.png" />
                  <img v-else class="chapter_icon" src="../../assets/images/other/chapter_icon_gray.png" />
                  <div class="flex-1 chapter_name">{{ item.chapter }}</div>
                </div>
              </template>
              <div v-for="(child, idx) in item.children" :key="idx" class="chapter_item flex">
                <div class="play_icon"></div>
                <div class="flex-1 chapter_name" @click="handleGoFull(child.videoLink)">{{ child.jointNum }} {{ child.jointName }}</div>
                <div class="download_wrap flex">
                  <div v-for="(it, i) in child.file.split(',')" :key="i" type="text" class="download" @click="handleDownload(it)">
                    <img v-if="it.split('.')[it.split('.').length - 1] == 'pdf'" class="chapter_icon" src="../../assets/images/other/pdf.png" />
                    <img v-else-if="it.split('.')[it.split('.').length - 1] == 'docx' || it.split('.')[it.split('.').length - 1] == 'doc'" class="chapter_icon" src="../../assets/images/other/word.png" />
                    <img v-else class="chapter_icon" src="../../assets/images/other/file.png" />
                    <div class="download_txt">{{ it.split('/')[it.split('/').length - 1].split('.')[0] }}</div>
                  </div>
                  <div type="text" class="download" @click="handleDownload(child.videoLink)">
                    <img class="chapter_icon" src="../../assets/images/other/video_icon.png" />
                    <div class="download_txt">{{ child.videoLink.split('/')[child.videoLink.split('/').length - 1].split('.')[0] }}</div>
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="right">
        <el-tabs v-model="activeName">
          <el-tab-pane label="相关课程" name="first">
            <div v-if="recommends.length === 0" class="empty">
              暂无推荐
            </div>
            <div v-else class="relatedCourse">
              <div v-for="(item, index) in recommends.slice(0, 8)" :key="index" class="item" @click="handleGoDetail(item.id)">
                <img :src="item.pic" alt="" />
                <div class="content" v-html="item.content"></div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import CommenInfo from "./components/commenInfo.vue";
import HeaderContent from "@/views/Experiment/components/headerContent";
import {
  inStudyReply,
  queryStudyDetail,
  courseReply,
  showRecord,
  classRecommend
} from "@/api/ApiConfig";

export default {
  components: {
    CommenInfo,
    HeaderContent
  },
  data() {
    return {
      form: {
        evaluate: "" //评价内容
      },
      selIndex: 0,
      titleList: [
        {
          title: "课程介绍",
          value: 0
        },
        {
          title: "评论区",
          value: 1
        },
        {
          title: "课程文件",
          value: 3
        }
      ],
      data: {},
      evalData: [],
      pageTotal: 0,
      pageIndex: 1,
      pageSize: 10,
      activeName: "first",
      accordionName: '',
      recommends: []
    };
  },
  watch: {
    $route() {
      this.getData();
      this.getEvaluateList();
      this.classRecommend();
    }
  },
  mounted() {
    this.getData();
    this.getEvaluateList();
    this.classRecommend();
  },
  methods: {
    classRecommend() {
      classRecommend({
        channel: '课程'
      }).then(res => {
        if (res.code === 200) {
          this.recommends = res.data || [];
        }
      });
    },
    getData() {
      queryStudyDetail({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          res.data.descs = res.data.descs ? JSON.parse(res.data.descs) : []
          this.data = res.data;
        }
      });
      showRecord({
        channel: "课程",
        id: this.$route.query.id
      });
    },
    // 学员评价
    getEvaluateList() {
      courseReply({
        id: this.$route.query.id,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code === 200) {
          this.evalData = res.data.list;
          this.pageTotal = res.data.total;
        }
      });
    },
    // 分页导航
    handleSizeChange(val) {
      this.pageSize = val;
      this.getEvaluateList();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getEvaluateList();
    },
    handleSubmitReplay(data) {
      inStudyReply(data).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: "success"
          });
          this.getEvaluateList();
          this.form.evaluate = "";
        }
      });
    },
    handleSubmit() {
      inStudyReply({
        content: this.form.evaluate,
        id: this.$route.query.id,
        parentId: '',
        toId: ''
      }).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: "success"
          });
          this.getEvaluateList();
          this.form.evaluate = "";
        }
      });
    },
    handletitleClick(index) {
      this.selIndex = index;
    },
    handleDownload(path) {
      let loginFlag = localStorage.getItem("pcToken") || "";
      if (!loginFlag) {
        this.$message.error("请登录");
        setTimeout(() => {
          this.$router.replace("/login");
        }, 1500);
        return;
      } else {
        if(this.data.dowland === '1') {
          window.open(path)
        } else {
          this.$alert('文件不允许查看', '提示', { confirmButtonText: '确定' });
          // this.$message.error('文件不允许查看')
        }
      }
    },
    handleGoDetail(id) {
      this.$router.push({
        path: "/course/Detail",
        query: {
          id
        }
      });
    },
    handleGoFull(videoLink) {
      let loginFlag = localStorage.getItem("pcToken") || "";
      if (!loginFlag) {
        this.$message.error("请登录");
        setTimeout(() => {
          this.$router.replace("/login");
        }, 1500);
        return;
      } else {
        const data = this.data
        data.collectNum = Number(data.collectNum)
        data.filterContent = data.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, "");
        data.videoSrc = videoLink
        let routeData = this.$router.resolve({
          path: "/course/fullscreen",
          query: {
            id: this.$route.query.id,
            videoLink: videoLink
          }
        });
        window.open(routeData.href, '_blank');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.box_1200 {
  width: 1200px;
  height: 100%;
}
.Course_detail {
  width: 100%;
  padding-top: 72px;
  .up {
    height: 498px;
    padding: 30px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 4px 30px 0px rgba(0, 60, 164, 0.2);
    border-radius: 8px;
    .left {
      width: 660px;
      .title {
        margin-bottom: 30px;
        .title1 {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333e55;
        }
        .title2 {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          .label {
            color: #333333;
          }
          .value {
            color: #3989eb;
          }
        }
      }
      img {
        width: 100%;
        height: 370px;
      }
    }
    .right {
      width: 400px;
      position: relative;
      .title {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333e55;
        margin-bottom: 30px;
      }
      .content {
        .describe {
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 21px;
          margin-bottom: 17px;
        }
        .text {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        .mark {
          margin-top: 30px;
          p {
            img {
              width: 15px;
              height: 17px;
              margin-right: 7px;
            }
            span {
              font-size: 16px;
              font-family: Tensentype RuiHeiJ-W4;
              font-weight: 500;
              color: #333e55;
              margin-right: 40px;
            }
          }
        }
        .button {
          position: absolute;
          bottom: 30px;
          button {
            width: 180px;
            height: 46px;
            border-radius: 4px;
          }
          .btn1 {
            background: linear-gradient(135deg, #28b9f5, #3989eb);
          }
          .btn2 {
            background: linear-gradient(-45deg, #319538, #83bf54);
            border: none;
          }
        }
      }
    }
  }
  .center_box {
    margin-top: 20px;
    margin-bottom: 10px;
    .left {
      width: 780px;
      padding: 30px;
      box-sizing: border-box;
      background: #fff;
      box-shadow: 0px 4px 30px 0px rgba(0, 60, 164, 0.2);
      border-radius: 8px;
      .radio_title {
        width: 360px;
        height: 38px;
        background: #eef5ff;
        border-radius: 19px;
        margin-bottom: 33px;
        span {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          display: inline-block;
          width: 120px;
          text-align: center;
          height: 38px;
          line-height: 38px;
          border-radius: 16px;
          cursor: pointer;
        }
      }
      .profile_content {
        .title {
          position: relative;
          padding: 10px 0;
          margin-top: 15px;
          font-size: 16px;
          &::before {
            content: "";
            display: inline-block;
            width: 3px;
            height: 14px;
            background: #3989eb;
            border-radius: 2px;
            position: absolute;
            left: -7px;
            top: 14px;
          }
        }
        .identIcon {
          width: 17px;
          height: 16px;
          margin-right: 7px;
          position: relative;
          top: 3px;
        }
        .txt {
          font-size: 14px;
        }
      }
      .eva_contentBox {
        height: 790px;
        .eva_content {
          padding: 20px;
          border-bottom: 1px solid #e5e5e5;
          .eva_up {
            height: 38px;
            .info {
              img {
                width: 30px;
                height: 30px;
                background: #8e8e8e;
                border-radius: 50%;
                margin-right: 11px;
              }
              span {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #3989eb;
              }
            }
            .ava_time {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              margin-top: 22px;
            }
          }
          .eva_down {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            margin-top: 20px;
            margin-left: 47px;

            .replay {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #3989eb;
              margin-right: 10px;
              cursor: pointer;
            }
          }
        }
        .pagination {
          margin: 30px 0;
        }
        .comments_content {
          width: 100%;
          height: 97px;
          background: #ffffff;
          border-radius: 0px 0px 8px 8px;
          border-top: 1px solid #e5e5e5;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .commen_text {
            width: 630px;
            .el-input {
              height: 56px;
              /deep/.el-input__inner {
                height: 56px;
              }
            }
          }
          .comments_btn {
            width: 66px;
            height: 40px;
            line-height: 40px;
            color: #fff;
            background: #3989eb;
            border-radius: 4px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
    .right {
      width: 400px;
      padding: 30px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 4px 30px 0px rgba(0, 60, 164, 0.2);
      border-radius: 8px;
      .empty {
        padding: 20px;
        text-align: center;
        color: #666;
        font-size: 14px;
      }
      .relatedCourse {
        // height: 711px;
        // overflow-y: scroll;
        .item {
          cursor: pointer;
          margin-bottom: 30px;
          img {
            width: 320px;
            height: 180px;
            text-align: center;
            border-radius: 8px;
          }
          .content {
            width: 320px;
            max-height: 48px;
            line-height: 24px;
            margin-top: 10px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            overflow: hidden;
            text-align: center;
            color: #333333;
            font-size: 16px;
            font-weight: bold;
            font-family: Microsoft YaHei;
          }
        }
      }
    }

    .right_box {
      background: #ffffff;
      box-shadow: 0px 4px 30px 0px rgba(0, 60, 164, 0.2);
      border-radius: 8px;
      padding: 30px;
      box-sizing: border-box;
      width: 100%;
      position: relative;
      .tabs-box {
        /deep/ .el-tabs__item {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }

    ::-webkit-scrollbar {
      width: 2px;
      background: transparent;
    }
    .redu_content {
      font-size: 14px;
      line-height: 2;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      /deep/ img {
        max-width: 100%;
      }
    }
    .evaluate_box {
      .title {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333e55;
        &:before {
          display: inline-block;
          content: "";
          width: 3px;
          height: 14px;
          background: #333e55;
          margin-right: 10px;
        }
      }
      .compScore {
        .scor_content {
          .left,
          .right {
            width: 50%;
          }
          .left {
            position: relative;
            &::after {
              display: inline-block;
              content: "";
              width: 1px;
              height: 90px;
              background: linear-gradient(
                0deg,
                rgba(153, 153, 153, 0) 0%,
                rgba(153, 153, 153, 0.6) 50%,
                rgba(153, 153, 153, 0) 100%
              );
              position: absolute;
              right: 0;
              top: 0;
            }
            .score {
              text-align: center;
              font-size: 32px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #3989eb;
              span {
                font-size: 16px;
              }
            }
            .quan_mark {
              height: 26px;
              margin-top: 30px;
              img {
                width: 22px;
                height: 22px;
                margin: 0 13px;
              }
              .count {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #666666;
                margin-left: 20px;
                span {
                  color: #3989eb;
                }
              }
            }
          }
          .right {
            .item {
              margin-top: 10px;
              .jixing {
                margin-left: 10px;
              }
              span {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #666666;
              }
              img {
                width: 22px;
                height: 22px;
                margin: 0 13px;
              }
            }
          }
        }
      }
      .evaluate {
        margin-top: 40px;

        .eva_content {
          padding: 20px;
          border-bottom: 1px solid #e5e5e5;
          .eva_up {
            height: 38px;
            .info {
              img {
                width: 38px;
                height: 38px;
                background: #8e8e8e;
                border-radius: 50%;
                margin-right: 11px;
              }
              span {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
              }
            }
            .eva {
              .item {
                .jixing {
                  margin-left: 10px;
                }
                span {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #3989eb;
                }
                img {
                  width: 18px;
                  height: 18px;
                }
              }
              .item:nth-child(2) {
                margin-left: 30px;
              }
              .item:nth-child(3) {
                margin-left: 30px;
              }
            }
          }
          .eva_down {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            margin-top: 20px;
            margin-left: 47px;
            .ava_time {
              margin-top: 22px;
            }
          }
        }
        .pagination {
          margin-top: 30px;
        }
      }
    }
    .toEva {
      width: 90px;
      height: 28px;
      line-height: 28px;
      background: #ffffff;
      border: 1px solid #3989eb;
      border-radius: 14px;
      text-align: center;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #3989eb;
      position: absolute;
      right: 50px;
      cursor: pointer;
      z-index: 999;
    }
  }
  .eva_files {
    padding: 0 5px;
    .flex-1 {
      flex: 1;
    }
    /deep/.el-collapse-item__wrap {
      border-bottom: none;
    }
    .chapter_title {
      width: 100%;
      font-size: 14px;
      font-weight: bold;
      color: #333;
      line-height: 46px;
      align-items: center;
    }
    .accordion_item {
      margin-bottom: 6px;
      .chapter_name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
          color: #3989EB;
        }
      }
      &.is-active {
        .chapter_title {
          border-bottom: solid 1px #EBEEF5;
        }
      }
      /deep/.el-collapse-item__header {
        border-bottom: none;
      }
      /deep/.el-collapse-item__header, /deep/.el-collapse-item__wrap {
        background: #F8F8F8;
        padding: 0 18px;
      }
      &.actived {
        .chapter_title {
          color: #3989EB;
        }
      }
    }
    .chapter_item {
      align-items: center;
      width: 100%;
      font-size: 14px;
      font-weight: bold;
      color: #333;
      line-height: 40px;
      padding-left: 32px;
      cursor: pointer;
      .chapter_icon {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
      .play_icon {
        width: 18px;
        height: 18px;
        background: url('../../assets/images/other/play_gray.png') left center;
        background-size: 100% 100%;
        margin-right: 10px;
      }
      &:hover .play_icon {
        background: url('../../assets/images/other/play_blue.png') left center;
        background-size: 100% 100%;
      }
    }
    /deep/.el-collapse-item__content {
      padding-bottom: 0;
    }
    .download {
      text-decoration: underline;
    }
    .chapter_icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    .download_wrap {
      display: flex;
      max-width: 520px;
    }
    .download {
      width: 120px;
      margin: 0 5px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #333;
      .chapter_icon {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
      .download_txt {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
          color: #3989EB;
        }
      }
    }
  }
}
</style>
