/**
 * 校验手机号
 * @param rule
 * @param value
 * @param callback
 */
 export function verifyMobile(rule, value, callback) {
    if (value === ""||!value) {
        callback();
    } else {
        const reg = /^1[3456789]\d{9}$/;
        // const reg = /^((17[0-9])|(14[0-9])|(13[0-9])|(15[^4,D])|(18[0,5-9]))\d{8}$/;
        if (!reg.test(value)) {
            callback(new Error("请填写正确的手机号！"));
        } else {
            callback();
        }
    }
}

/**
 * 校验身份证号
 * @param rule
 * @param value
 * @param callback
 */
 export function cardId(rule, value, callback) {
    if (value === ""||!value) {
        callback();
    } else {
        // const reg = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/g;
        if (!validateCertificateNo(value)) {
            callback(new Error("请填写有效的身份证号！"));
        } else {
            callback();
        }
    }
}

/**
 * 校验邮箱
 * @param rule
 * @param value
 * @param callback
 */
 export function email(rule, value, callback) {
    if (value === ""||!value) {
        callback();
    } else {
        const regCode = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+(\.([a-zA-Z]{2,4})){0,1}\.([a-zA-Z]{2,4})$/;
        if (!regCode.test(value)) {
            callback(new Error("请填写有效邮箱！"));
        } else {
            callback();
        }
    }
}

/**
 * 校验字符串为正整数
 * @param rule
 * @param value
 * @param callback
 */
 export function isPositiveInteger(rule, value, callback) {
    if (value === ""||!value) {
        callback();
    } else {
        const regCode = /(^[1-9]\d*$)/g
        if (!regCode.test(value)) {
            callback(new Error('仅支持正整数'));
        } else {
            callback();
        }
    }
}

// 判断是否为空
export function isEmpty(value) {
    if (value === undefined || value === 'undefined' || value === '' || value === 'null' || value === null) {
      return true
    }
    return false
  }