<template>
  <div class="resource-pager">
    <div class="classify flex jc-between ai-center">
      <div v-for="(item, index) in types" :key="index"
        :class="['item cursor-pointer', { actived: actived === item.id }]" @click="handleTypeClick(item)">{{ item.name
        }}</div>
    </div>
    <div class="table flex flex-wrap">
      <div v-for="(item, index) in list" :key="index" class="item flex flex-column relative cursor-pointer">
        <!-- 文本文档 -->
        <div v-if="item.fileType == '14'" class="img flex jc-center ai-center">
          <i class="el-icon-document" style="font-size: 180px;color: #ccc;" @click="handleOpenWorld('https://aiht.cyzntech.com/File/' + item.fileUrl)"></i>
        </div>
        <!-- <div v-if="item.fileType == '14'" class="img">
          <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=' + item.fileUrl" width='260px' height="244px" frameborder="0" class="iframe"></iframe>
        </div> -->
        <!-- 图片 -->
        <el-image v-else-if="item.fileType == '18'" class="img" :src="'https://aiht.cyzntech.com/File/' + item.fileUrl" :preview-src-list="['https://aiht.cyzntech.com/File/' + item.fileUrl]" />
        <!-- 视频资料 -->
        <video
          v-else-if="item.fileType == '15'"
          class="img"
          controls
          :src="'https://aiht.cyzntech.com/File/' + item.fileUrl"
        ></video>
        <!-- 全景图资料 -->
        <el-image v-else-if="item.fileType == '16'" class="img" :src="'https://aiht.cyzntech.com/File/' + item.fileUrl" :preview-src-list="['https://aiht.cyzntech.com/File/' + item.fileUrl]" />
        <!-- 模型资料 -->
        <div v-else-if="item.fileType == '17'" class="img">
          <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=' + item.fileUrl" width='260px' height="244px" frameborder="0" class="iframe"></iframe>
        </div>
        <div class="txt">{{ item.fileName }}</div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[10, 20, 40, 50, 100]" :page-size="size"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- <img src="../../assets/pcResource.png" alt=""> -->
  </div>
</template>
<script>
import { getResource } from '@/api/ApiConfig'
export default {
  components: {
  },
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      actived: '',
      types: [
        {
          id: '',
          name: '全部资料'
        },
        {
          id: '14',
          name: '文本文档'
        },
        {
          id: '15',
          name: '视频资料'
        },
        {
          id: '18',
          name: '图片资料'
        },
        {
          id: '16',
          name: '全景图资料'
        },
        {
          id: '17',
          name: '模型资料'
        }
      ],
      page: 1,
      size: 10,
      total: 10,
      list: []
    };
  },
  created() {
    this.getData()
  },
  activated() {
  },
  methods: {
    handleOpenWorld(path) {
      if(['docx', 'docm', 'dotm', 'dotx', 'xlsx', 'xlsb', 'xls', 'xlsm', 'pptx', 'ppsx', 'ppt', 'pps', 'pptm', 'potm', 'ppam', 'potx', 'ppsm'].some(item => path.slice(-4).includes(item))) {
        path = 'https://view.officeapps.live.com/op/view.aspx?src=' + path
      }
      window.open(path, "_blank");
    },
    handleTypeClick(item) {
      this.actived = item.id
      this.page = 1
      this.getData()
    },
    getData() {
      getResource({
        pageIndex: this.page,
        pageSize: this.size,
        fileType: this.actived,
        studyId: this.laboratory_id
      }).then(res => {
        if (res.code === 200) {
          this.list = res.data.records || [];
          this.total = res.data.total
        }
      });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
  }
};
</script>
<style lang="scss">
.resource-pager {
  padding-top: 39px;

  .classify {
    width: 1400px;
    margin: 0 auto;

    .item {
      box-shadow: 0px 2px 14px 0px rgba(45, 77, 130, 0.11);
      border-radius: 10px;
      font-weight: bold;
      font-size: 20px;
      line-height: 76px;
      margin: 0 30px;
      padding: 0 38px;
      background: #FFFFFF;
      color: #666666;

      &.actived {
        background: #327CED;
        color: #FFFFFF;
      }
    }
  }

  .table {
    width: 1400px;
    margin: 20px auto;

    .item {
      width: 260px;
      height: 279px;
      margin: 10px;
      img, video {
        object-fit: contain;
      }
      .img {
        width: 260px;
        height: 244px;
        overflow: hidden;
        object-fit: contain;
        background: #ffffff;
        .iframe {
          width: 100%;
          height: 100%;
        }
      }

      .txt {
        width: 280px;
        font-weight: bold;
        font-size: 18px;
        color: #234984;
        line-height: 25px;
        text-align: center;
        margin-top: 10px;
      }
    }
  }

  .pagination {
    margin: 30px 0;
  }
}
</style>
