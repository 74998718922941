<template>
  <div class="setting-pager">
    <div class="setting-container">
      <el-form :model="form" :rules="rules" ref="formRef" label-position="top">
        <el-form-item label="公司名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="备案编号" prop="recordNumber">
          <el-input v-model="form.recordNumber" placeholder="请输入备案内容"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="sysPhone">
          <el-input v-model="form.sysPhone" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="上传logo" prop="logo">
          <div class="flex jc-center">
            <el-upload :headers="{ Token }" class="avatar-uploader" action="/studyPc/upload/uploadPicture"
              accept="image/*" :show-file-list="false" :on-success="httpRequestSuccess"
              :before-upload="beforeAvatarUpload">
              <img v-if="form.logo" :src="form.logo" class="avatar">
              <img v-else src="../images/upload-icon.png" class="avatar-uploader-icon">
              <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
            </el-upload>
          </div>
        </el-form-item>
        <div class="btns flex jc-center ai-center">
          <el-button size="small" type="primary" class="btn" @click="onSubmit()">保存</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { queryStudyDetail, setSysInfo } from '@/api/ApiConfig'
import * as rules from "@/utils/rules";
export default {
  components: {},
  props: ['pager', 'params'],
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      Token: localStorage.getItem('pcToken') || '',
      form: {
        logo: "",
        name: "",
        recordNumber: "",
        sysPhone: ""
      },
      rules: {
        logo: [{ required: true, message: "logo不能为空" , trigger: "blur"}],
        name: [{ required: true, message: "公司名称不能为空" , trigger: "blur"}],
        recordNumber: [{ required: true, message: "备案编号不能为空" , trigger: "blur"}],
        sysPhone: [
          { required: true, message: "联系电话不能为空" , trigger: "blur"},
          { validator: rules.verifyMobile, trigger: 'blur' }
        ]
      }
    };
  },
  watch: {
    pager() {
    },
    params() {
    }
  },
  created() {
    this.getSysInfo()
  },
  mounted() {
  },
  methods: {
    getSysInfo() {
      queryStudyDetail({
        id: this.laboratory_id
      }).then(res => {
        if (res.code === 200) {
          this.form = {
            logo: res.data.logo,
            name: res.data.sysName,
            recordNumber: res.data.recordNumber,
            sysPhone: res.data.sysPhone
          }
        }
      });
    },
    async httpRequestSuccess(res) {
      this.form.logo = `/studyPc/upload/avoid/view?fileName=${res.data}`;
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    async onSubmit() {
      const res = await setSysInfo({
        id: this.laboratory_id,
        logo: this.form.logo,
        sysName: this.form.name,
        recordNumber: this.form.recordNumber,
        sysPhone: this.form.sysPhone
      })
      if (res.code === 200) {
        this.$message.success("保存成功");
      } else {
        this.$message.success("保存失败");
      }
      this.getSysInfo()
    }
  }
};
</script>
<style lang="scss" scoped>
.setting-pager {
  background-color: #F4F5F8;
  overflow: hidden;

  .setting-container {
    width: 1200px;
    margin: 50px auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px 0px rgba(45, 77, 130, 0.11);
    border-radius: 20px;
    opacity: 0.86;
    padding: 30px;
  }
}

/deep/ .el-form--label-top .el-form-item__label {
  font-weight: bold;
  font-size: 22px;
  color: #575757;
  line-height: 37px;
}

/deep/ .el-input__inner {
  height: 54px;
  border-radius: 18px;
  border: 1px dashed #979797;
  font-size: 16px;
  color: #999999;
  line-height: 54px;
}

/deep/.avatar-uploader {
  .el-upload {
    // border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader-icon {
    width: 268px;
    height: 190px;
  }

  .avatar {
    width: 268px;
    height: auto;
    display: block;
  }
}
</style>
